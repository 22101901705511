<template>

	<div id="team">

		<div id="top">

			<div id="logo"><img src="@/assets/img/logo-random-ttfl.png" /></div>

			<div class="crawling-notice" v-if="is_phone()">
				<nav id="menu">
					<a class="btn" :href="'/' + ( this.$route.params.date ? this.$route.params.date  : '' )">Day</a>
				</nav>
			</div>

			<container container_id="week" :classes="{ noheader : true }">

				<template slot="container-body">

					<week :day="day" :base_url="'/team/'"></week>

					<nav id="menu" v-if="!is_phone()">
						<a class="btn" :href="'/' + ( this.$route.params.date ? this.$route.params.date  : '' )">Day</a>
					</nav>

				</template>

			</container>

		</div>

		<day-recap :users="users" :day="day"></day-recap>
		<deck-recap :day="day" v-if="!is_phone()"></deck-recap>
		<!-- <month-recap :users="users" :day="day" v-if="!is_phone()"></month-recap> -->
		
		<!-- <team-chart :day="this.day" v-if="!is_phone()"></team-chart> -->

	</div>

</template>

<script>

	import device from '@/mixins/device'

	export default {

		name	: 'team',

		components	: {

			'container'	: () => import( '@/components/container.vue' ), 
			'week'	: () => import( '@/components/week.vue' ), 

			'day-recap'	: () => import( '@/views/team/day-recap.vue' ), 
			'deck-recap'	: () => import( '@/views/team/deck-recap.vue' ), 
			// 'month-recap'	: () => import( '@/views/team/month-recap.vue' ), 

			// 'team-chart'	: () => import( '@/components/team/team-chart.vue' ),

		},

		data	: function() { return {

			day	: this.$moment(),
			day_before	: false,

			users	: [],

			day_ranking	: 0,
			evo	: 0,
			day_picks_total	: 0,
			day_estims_total	: 0,

			screenshot	: false,

		}},

		mixins	: [ device ],

		methods	: {
		},

		watch : {

			get_rankings	: function() {

				var _this	= this
				
				var day_before_before	= this.$moment( _this.day_before.valueOf() )
				day_before_before.subtract( 1, 'days' )

				this.$store.state.team.rankings.forEach(function( ranking ) {

					if ( _this.day_before.format( 'YYYY-MM-DD' ) == ranking.date ) {
						_this.day_ranking	= parseInt( ranking.ranking )
					}

					if ( day_before_before.format( 'YYYY-MM-DD' ) == ranking.date ) {
						_this.day_before_ranking	= parseInt( ranking.ranking )
					}

				})

				this.evo	= this.day_before_ranking - this.day_ranking

			},
			
		},

		computed	: {

			get_users_api() {
				return this.$store.state.team.users
			},

			get_rankings() {
				return this.$store.state.team.rankings
			},

			get_picks() {
				return this.$store.state.team.picks
			},

		},

		mounted	: function() {

			this.$store.dispatch( 'team/users_from_api', {
				attempts	: 0,
			})

			var today_string	= this.day.format( 'YYYY-MM-DD' ) + ' 00:00:00'
			
			if ( typeof this.$route.params.date != 'undefined' ) {
				today_string	= this.$route.params.date + ' 00:00:00';
			}

			this.day	= this.$moment( today_string )

		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';
	@import '@/assets/scss/font-avenger';
	@import '@/assets/scss/datas-table';



	/** ---------------------------------------*\
		Top
	*---------------------------------------- **/

	#top::v-deep .container {

		width: 100%;
		margin-top: 0;

		.container-body {

			@include flex_display;

			justify-content: space-between;
			flex-flow: row nowrap;

			@include mobile() {
				align-items: stretch;
			}
			
		}

		.crawling-notice {
			display: block;
			margin-right: 25px;
			font-size: 0.875em;
		}

		.container-body > div {
			flex: auto;
			max-width: 100%;
		}

	}

	/** ---------------------------------------*\
		Week container
		Mobile
	*---------------------------------------- **/
	
	#app.device-phone #week.container::v-deep > div {

		border: 0;
		background-color: transparent;

		.container-body {
			padding: 0;
		}

	}

	/** ---------------------------------------*\
		Header
	*---------------------------------------- **/

	.summary::v-deep .group .container-head,
	.container.container-datas::v-deep .container-head,
	.group::v-deep .container .container-head {

		align-items: stretch;
		padding: 0;

		& > div {
			@include flex_display;
			align-content: stretch;
		}

		& > div > div {
			@include flex_display;
			height: 100%;
			padding: 10px 15px;
		}

		div.even {
			background-color: darken( #FFF, 7% );
		}

		button.toggle {
			height: 100%;
		}

	}

	/** ---------------------------------------*\
		Container body
	*---------------------------------------- **/

	.summary::v-deep .group .container-datas .container-body,
	.container.container-datas::v-deep .container-body,
	.group::v-deep .container.container-datas .container-body {

		padding: 0;
		text-transform: uppercase;

	}

	/** ---------------------------------------*\
		Datas table TH
	*---------------------------------------- **/

	.summary::v-deep .group .container.container-datas .datas-table,
	.container-datas::v-deep .datas-table,
	.group::v-deep .container.container-datas .datas-table {

		th {
			text-transform: none;
		}

		th img {
			width: 20px;
			height: 20px;
			object-fit: contain;
		}

	}

	/** ---------------------------------------*\
		Red & green
	*---------------------------------------- **/

	#team,
	#team .container::v-deep,
	#team .group::v-deep .container {

		.red {
			color: $red;
		}

		.green {
			color: $green;
		}

	}

	/** ---------------------------------------*\
		Datas table rows
	*---------------------------------------- **/

	#team .summary::v-deep .container,
	#team .container::v-deep,
	#team .group::v-deep .container {

		tr.row-red td {
			background: transparentize( $red, 0.9 );
		}

		tr.row-green td {
			background: transparentize( $green, 0.9 );
		}

		td.pick {

			& > div {
				justify-content: space-between;
			}

			& > div > div {
				@include flex_display;
				justify-content: space-between;
			}

			span {
				width: 65px;
			}

			img {
				display: inline-block;
			}

			img.team-logo {
				width: 30px;
				height: auto;
				margin-right: 10px;
				object-fit: contain;
			}

		}

		td.score span.score-day {
			margin-right: 10px;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#team .summary::v-deep .group .container .container-head,
	#team .group::v-deep .container .container-head {

		flex-flow: row nowrap;

		& > div.head-main {
			padding: 0;
		}
		
		& > div.head-main > div {
			flex: auto;
			justify-content: center;
			height: 50px;
			padding-left: 20px;
			padding-right: 20px;
			font-size: 1.2em;
			line-height: 1em;
		}

		& > div.head-main > div.group-title {

			flex: 170px;
			max-width: 170px;
			padding: 5px;
			background-color: $red;
			@include font_avenger;
			color: #FFF;
			font-size: 1.6em;
			line-height: 45px;

			&.load.loading {
				animation: loader 1.2s $ease-inout-expo infinite;
			}

			@keyframes loader {

				0% {
					background: $red;
					color: #FFF;
				}

				50% {
					background: transparentize( $red, 1 );
					color: #000;
				}

				100% {
					background: $red;
					color: #FFF;
				}

			}
			
		}

		& > div > div.div-inputs {
			justify-content: flex-start;
			padding: 0;
		}

		& > div > div.deck-number {
			flex: 170px;
			max-width: 170px;
		}

		& > div > div > span,
		& > div > div > span > span {
			margin-left: 5px;
			font-size: 1.1em;
			line-height: 1em;
		}

		& > div > div.btn-screenshot {

			padding: 0;
			border-right: 0;

			button,
			button:hover {
				
				border: 0;
				background-color: transparent;
				color: #000;
				font-size: inherit;

			}

			button:hover {
				color: $red;
			}

		}

		.head-actions {
			transition: opacity 300ms linear;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	// #team .group::v-deep .container.load.loading .container-head .head-actions,
	#team .group::v-deep.nodata .container .container-head .head-actions {

		opacity: 0;
		pointer-events: none;
		
	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#team .summary::v-deep .group {

		.screenshot-top {

			@include flex_display;
			margin-top: 25px;

			img {
				width: 100px;
			}

			& > div {
				@include flex_display;
				align-items: flex-start;
			}

			& > div > div {

				margin-left: 35px;
				@include font_avenger;
				font-size: 2em;
				color: #FFF;

				em {
					@include font_montserrat;
					font-style: normal;
					font-weight: bold;
					line-height: 0em;
				}

			}

			.black {
				color: #000;
			}

			.red {
				color: $red2;
			}

			.green {
				color: $green;
			}

		}

		.container-datas {
			margin-top: 25px;
		}

	}

</style>